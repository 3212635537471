import Logo from "../../assets/logo.svg";

// material-ui
import {
  Grid,
  Box,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { AuthForm } from "./AuthForm";


export function AuthPage() {

  return (
    <Box sx={{ minHeight: "100vh" }}>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx={{
          minHeight: "100vh",
        }}
      >
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              minHeight: {
                xs: "calc(100vh - 134px)",
                md: "calc(100vh - 112px)",
              },
            }}
          >
            <Grid item>
            <Box sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 } }}>
              <Card
                sx={{
                  maxWidth: { xs: 400, lg: 475 },
                  margin: { xs: 2.5, md: 3 },
                  "& > *": {
                    flexGrow: 1,
                    flexBasis: "50%",
                  },
                }}
              >
                <Box sx={{ p: "16px 16px 0" }}>
                  <CardMedia
                    component="img"
                    height="78"
                    image={Logo}
                    alt="logo"
                  />
                </Box>
                <CardContent>
                  <AuthForm />
                </CardContent>
              </Card>
            </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
