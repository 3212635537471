import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';

const menuConfig = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    url: 'dashboard',
    icon: DashboardIcon
  },
  {
    id: 'settings',
    title: 'Settings',
    type: 'item',
    url: 'settings',
    icon: SettingsApplicationsIcon
  },
];

export default menuConfig;