import { Alert, AlertColor, Snackbar } from "@mui/material"
import { useEffect, useState } from "react";

export function ErrorAlerts(props: {error: string}) {

  const [state, setState] = useState<{
    snackbarOpen: boolean;
    snackbarMessage: string;
    alertSeverity: AlertColor;
  }>({
    snackbarOpen: false,
    snackbarMessage: '',
    alertSeverity: 'warning'
  });

  const snackbarClose = () => {
    setState({...state, snackbarOpen: false});
  }

  const snackbarOpen = (message = 'Somthing wrong!', severity: AlertColor = 'error') => {
    setState({
      snackbarOpen: true,
      snackbarMessage: message,
      alertSeverity: severity
    });
  }

  useEffect(() => {
    if (props.error.length) {
      snackbarOpen(props.error);
    }
  }, []);

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
        open={state.snackbarOpen}
        onClose={snackbarClose}
        message={state.snackbarMessage}
        key={'errorSnackbar'} >
        <Alert onClose={snackbarClose} severity={state.alertSeverity} variant="filled" sx={{ width: '100%' }}>
          {state.snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}