import './App.css';
import { Route, Routes } from 'react-router';
import Layout from './layouts/Layout';
import { AuthPage } from './pages/auth/AuthPage';
import RequireAuth from './pages/auth/RequireAuth';
import { DashboardPage } from './pages/DashboardPage';
import { SettingsPage } from './pages/SettingsPage';
import ThemeCustomization from './themes';

function App() {

  return (
    <ThemeCustomization>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route index element={<AuthPage />} />

          {/* protected routes */}
          <Route element={<RequireAuth />}>
            <Route index path="dashboard" element={<DashboardPage />} />
            <Route path="settings" element={<SettingsPage />} />
          </Route>

        </Route>
      </Routes>
    </ThemeCustomization>
  );
}

export default App;
