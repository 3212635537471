import { ICheckStructure, IServerMonitoring, IStatusService } from './../../models/models';
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IServerMonitoringState {
  loading: boolean;
  error: string;
  statusServices: IStatusService[];
  serverMonitoring: IServerMonitoring;
  checkStructureServices: ICheckStructure;
}

function getInitialState(): IServerMonitoringState {
  return {
    loading: false,
    error: '',
    statusServices: [],
    serverMonitoring: {} as IServerMonitoring,
    checkStructureServices: {} as ICheckStructure
  }
}

const initialState:IServerMonitoringState = getInitialState();

type PayloadData = [IStatusService[], IServerMonitoring, ICheckStructure];

export const serverMonitoringSlice = createSlice({
  name: 'serverMonitoring',
  initialState,
  reducers: {
    serverMonitoringFetching(state) {
      state.loading = true;
    },
    serverMonitoringFetchSuccess(state, action: PayloadAction<PayloadData>) {
      state.loading = false;
      state.statusServices = action.payload[0];
      state.serverMonitoring = action.payload[1];
      state.checkStructureServices = action.payload[2];
    },
    serverMonitoringFetchError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action?.payload?.message;
    }
  }
});

export default serverMonitoringSlice.reducer;