import { useLocation, Navigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux";
import { MainPage } from "../MainPage";

const RequireAuth = () => {
  const {isAuthenticated} = useAppSelector(state => state.authReducer);
  const location = useLocation();

  return (
    isAuthenticated
      ? <MainPage />
      : <Navigate to="/" state={{ from: location }} replace />
  );
}
export default RequireAuth;