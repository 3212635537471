import axios from "axios"
import { AppDispatch } from ".."
import { IAuth, IAuthData } from "../../models/models"
import { authSlice } from "../slices/AuthSlice"

const baseURL = process.env.REACT_APP_API_URL || 'http://maps-api.firemap.org/api/';
const API_URL = `${baseURL}auth/`;

export const login = (data: IAuth) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(authSlice.actions.loginning());
      const response = await axios.post<IAuthData>(`${API_URL}login`, data);
      dispatch(authSlice.actions.loginSuccess({
        access: response.data.access_token,
        user: response.data.user,
        expire: response.data.expires_in
      }));
    } catch (e) {
      console.log('Error Login', e);
      dispatch(authSlice.actions.loginError(e as Error));
    }
  }
}