import { ICheckStructureData, IServerMonitoringData, IStatusServiceData } from '../../models/models';
import { serverMonitoringSlice } from '../slices/ServerMonitoringSlice';
import { AppDispatch } from './../index';
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL || 'http://maps-api.firemap.org/api/';


export const fetchServerMonitoring = () => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(serverMonitoringSlice.actions.serverMonitoringFetching());
      const getStatusServices = axios.get<IStatusServiceData>(`${baseURL}get-status-services`);
      const getServerMonitoring = axios.get<IServerMonitoringData>(`${baseURL}server-monitoring`);
      const getCheckStructureServicesData = axios.get<ICheckStructureData>(`${baseURL}check-structure-services-data`);

      const responses = await Promise.all([
        getStatusServices,
        getServerMonitoring,
        getCheckStructureServicesData
      ]);
      dispatch(serverMonitoringSlice.actions.serverMonitoringFetchSuccess([
        responses[0].data.data,
        responses[1].data.data,
        responses[2].data.data
      ]));
    } catch (e) {
      dispatch(serverMonitoringSlice.actions.serverMonitoringFetchError(e as Error));
    }
  }
};