import './DashboardPage.css'
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { fetchServerMonitoring } from "../store/actions/ServerMonitoringActions";
import { Loader } from "../components/Loader";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import { CircularProgressWithLabel } from '../components/CircularProgressWithLabel';
import MemoryIcon from '@mui/icons-material/Memory';
import SdCardIcon from '@mui/icons-material/SdCard';
import StorageIcon from '@mui/icons-material/Storage';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { ErrorAlerts } from '../components/ErrorAlerts';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.primary,
  minHeight: 200
}));

const ItemStatus = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.primary
}));

export function DashboardPage() {
  const {
    loading,
    error,
    statusServices,
    serverMonitoring,
    checkStructureServices} = useAppSelector(state => state.serverMonitoringReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchServerMonitoring());
  }, [dispatch]);

  if(loading) {
    return <Loader />
  }
  return(
    <Box sx={{ flexGrow: 1 }} className="dashboard">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <Item className="dashboard-card">
            <div className="card-title">
              <MemoryIcon sx={{ fontSize: 40 }} />
              <Typography variant="h4">
                CPU
              </Typography>
            </div>
            <div className="card-percent">
              <CircularProgressWithLabel
                size={80}
                thickness={8}
                value={serverMonitoring.cpuLoadPercentage}
                fontSize={18} />
            </div>
            <div>
            <Typography variant="h5">
                Load: {serverMonitoring.load}
              </Typography>
            </div>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <Item>
            <div className="card-title">
              <SdCardIcon sx={{ fontSize: 40 }} />
              <Typography variant="h4">
                Memory
              </Typography>
            </div>
            <div className="card-percent">
              <CircularProgressWithLabel
                size={80}
                thickness={8}
                value={serverMonitoring.memoryPercentage}
                fontSize={18} />
            </div>
            <div>
              <Typography variant="h5">
                Total RAM: {serverMonitoring.totalRamGB} Gb
              </Typography>
            </div>
            <div>
              <Typography variant="h5">
                Used RAM: {serverMonitoring.usedMemGB} Gb
              </Typography>
            </div>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <Item>
            <div className="card-title">
              <StorageIcon sx={{ fontSize: 40 }} />
              <Typography variant="h4">
                Storage
              </Typography>
            </div>
            <div className="card-percent">
              <CircularProgressWithLabel
                size={80}
                thickness={8}
                value={serverMonitoring.diskUsePercentage}
                fontSize={18} />
            </div>
            <div>
              <Typography variant="h5">
                Total disk: {serverMonitoring.totalDiskSizeGB} Gb
              </Typography>
            </div>
            <div>
              <Typography variant="h5">
                Used disk: {serverMonitoring.diskUsedSizeGB} Gb
              </Typography>
            </div>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <Item>
            <div className="card-title">
              <CompareArrowsIcon sx={{ fontSize: 40 }} />
              <Typography variant="h4">
                Swap
              </Typography>
            </div>
            <div className="card-percent">
              <CircularProgressWithLabel
                size={80}
                thickness={8}
                value={serverMonitoring.swapPercentage}
                fontSize={18} />
            </div>
            <div>
              <Typography variant="h5">
                Total disk: {serverMonitoring.totalSwapGB} Gb
              </Typography>
            </div>
            <div>
              <Typography variant="h5">
                Used disk: {serverMonitoring.usedSwapGB} Gb
              </Typography>
            </div>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <ItemStatus>
            <div className="card-title">
              <ElectricalServicesIcon sx={{ fontSize: 40 }} />
              <Typography variant="h4">
                Services status
              </Typography>
            </div>
            <Stack sx={{ width: '100%' }} spacing={1}>
              {statusServices.map((el, index) => (
                <Alert key={index} severity={el.code === 200 ? 'success': 'error'}>
                  <AlertTitle>{el.code === 200 ? 'Success': 'Error'}</AlertTitle>
                  <strong>{el.data}</strong>
                </Alert>
              ))}
            </Stack>
          </ItemStatus>
        </Grid>
      </Grid>
      <ErrorAlerts error={error} />
    </Box>
  );
}