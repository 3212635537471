import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { IUser } from "../../models/models";

interface AuthState {
  loading: boolean;
  error: string;
  isAuthenticated: boolean;
  access: string;
  user: IUser | string;
}

const ACCESS_KEY = 'ad-access';
const USER_KEY = 'ad-user';
const EXPIRES_KEY = 'ad-expires';

function getInitialState(): AuthState {
  const expiresIn = localStorage.getItem(EXPIRES_KEY) ?? null;
  const userStr = localStorage.getItem(USER_KEY) ?? null;

  if (expiresIn && new Date() > new Date(expiresIn)) {
    return {
      loading: false,
      error: '',
      isAuthenticated: false,
      access: '',
      user: ''
    }
  }

  return {
    loading: false,
    error: '',
    isAuthenticated: Boolean(localStorage.getItem(ACCESS_KEY) ?? ''),
    access: localStorage.getItem(ACCESS_KEY) ?? '',
    user: userStr ? JSON.parse(userStr) : ''
  }
}

const initialState: AuthState = getInitialState();

interface AuthPayload {
  access: string;
  user: IUser;
  expire: number;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state: any) {
      state.isAuthenticated = false;
      state.access = '';
      state.user = '';
      localStorage.removeItem(ACCESS_KEY);
      localStorage.removeItem(USER_KEY);
      localStorage.removeItem(EXPIRES_KEY);
    },
    loginning(state) {
      state.loading = true;
    },
    loginSuccess(state: any, action: PayloadAction<AuthPayload>) {
      state.loading = false;
      state.access = action.payload.access;
      state.user = action.payload.user;
      state.expire = action.payload.expire;
      state.isAuthenticated = Boolean(action.payload.access);

      const tokenExpires = new Date(Date.now() + (action.payload.expire * 1000));

      localStorage.setItem(ACCESS_KEY, action.payload.access);
      localStorage.setItem(USER_KEY, JSON.stringify(action.payload.user));
      localStorage.setItem(EXPIRES_KEY, tokenExpires.toString());
    },
    loginError(state, action: PayloadAction<Error>) {
      state.loading = false;
      state.error = action?.payload?.message;
    }
  }
});

export default authSlice.reducer;