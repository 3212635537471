import './Loader.css';
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export function Loader() {
  return(
    <div className='loader'>
      <Box sx={{ display: 'flex' }}>
        <CircularProgress
          size={200}
          thickness={4}
        />
      </Box>
    </div>
  );
}